import React, { createContext, useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Image, Button, StatusBar, SafeAreaView } from 'react-native';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator, HeaderBackButton } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import ModalDropdown from 'react-native-modal-dropdown';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import * as SecureStore from 'expo-secure-store';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import * as Notifications from 'expo-notifications';

import Login from './screens/Login';
import HomeList from './screens/HomeList';
import ChatList from './screens/ChatList';
import Chat from './screens/Chat';
import Profile from './screens/Profile';

import { AuthContext } from './utils/ContextUtils';
// NOTE: serviceWorker使うとアプリ実行しようとするとでエラーになる
// アプリ実行するときは下にあるserviceWorkerRegistrationもコメントアウトする
import * as serviceWorkerRegistration from './service_worker/serviceWorkerRegistration';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

// ドロップダウンメニュー
//const dropdownOptions = ['プロフィール', 'ログアウト'];
const dropdownOptions = ['ログアウト'];
function onSelectDropdown(navigation, index) {
  console.log('==================== App.onSelectDropdown start.');
  console.log('index: ' + index);
  //  if (index == 0) {
  //    // プロフィール
  //    navigation.navigate('Profile');
  //  } else if (index == 1) {
  // ログアウト
  //SecureStore.deleteItemAsync('access-token');
  //SecureStore.deleteItemAsync('client');
  //SecureStore.deleteItemAsync('uid');
  //SecureStore.deleteItemAsync('user-info');
  navigation.navigate('Login', { logout: true });
  //  }
  console.log('==================== App.onSelectDropdown end.');
}

function getHeaderTitle(navigation) {
  console.log('==================== App.getHeaderTitle start.');
  const routeName = navigation.state
    ? navigation.state.routes[navigation.state.index].routeName
    : 'Home';
  let title = 'ホーム';
  switch (routeName) {
    case 'Farm':
      title = 'トーク';
      break;
    case 'Profile':
      title = 'プロフィール';
      break;
  }
  console.log(routeName);
  console.log(title);
  console.log('==================== App.getHeaderTitle end.');
  return title;
}

//export const User = React.createContext();

export default function App() {
  const bottomTabNavigator = createBottomTabNavigator(
    {
      Home: {
        screen: HomeList,
        navigationOptions: {
          title: 'ホーム',
          tabBarIcon: ({ tintColor }) => <FontAwesome5 name="home" size={20} color={tintColor} />,
        },
      },
      Farm: {
        screen: ChatList,
        navigationOptions: {
          title: 'トーク',
          tabBarIcon: ({ tintColor }) => (
            <FontAwesome5 name="comment-dots" size={20} color={tintColor} />
          ),
        },
      },
      Profile: {
        screen: Profile,
        navigationOptions: {
          title: 'プロフィール',
          tabBarIcon: ({ tintColor }) => <FontAwesome5 name="user" size={20} color={tintColor} />,
        },
      },
    },
    {
      initialRouteName: 'Home',
      tabBarOptions: {
        activeTintColor: '#009387',
      },
    }
  );
  const stackNavigator = createStackNavigator(
    {
      ChatList: {
        screen: bottomTabNavigator, //ChatList,
        navigationOptions: ({ navigation }) => ({
          title: getHeaderTitle(navigation),
          headerRight: () => (
            <ModalDropdown
              options={dropdownOptions}
              onSelect={(index) => onSelectDropdown(navigation, index)}
              dropdownStyle={styles.dropdownMenu}
              dropdownTextStyle={styles.dropdownTextMenu}
            >
              <FontAwesome5 style={styles.otherButtonIcon} name="ellipsis-v" size={22} />
            </ModalDropdown>
          ),
        }),
      },
      Chat: {
        screen: Chat,
        navigationOptions: ({ navigation }) => ({
          title: '',
          //title: `${navigation.state.params.title}`,
          //headerBackTitle: `${navigation.state.params.title}`,
          headerTitleAlign: 'center',
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              style={styles.backButton}
              label={navigation.state.params.title}
              labelVisible={true}
              onPress={() => navigation.goBack()}
            />
          ),
        }),
      },
    },
    {
      initialRouteName: 'ChatList',
      defaultNavigationOptions: {
        headerStyle: {
          backgroundColor: '#009387',
        },
        headerTitleAlign: 'left',
        headerTintColor: 'white',
        //headerBackTitleVisible: false
      },
    }
  );
  const switchNavigator = createSwitchNavigator(
    {
      Login: { screen: Login },
      Main: { screen: stackNavigator },
      Profile: { screen: Profile },
    },
    {
      initialRouteName: 'Login',
    }
  );

  const MainNavigator = createAppContainer(switchNavigator);
  const userInfo = useState(null);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    // フォアグラウンド用通知リスナー
    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      console.log(notification);
    });

    // バックグラウンド用通知リスナー
    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
      console.log(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  return (
    <ActionSheetProvider>
      <AuthContext.Provider value={userInfo}>
        <StatusBar backgroundColor="#009387" barStyle="default" />
        <MainNavigator />
        <SafeAreaView />
      </AuthContext.Provider>
    </ActionSheetProvider>
  );
}

// pwa対応
serviceWorkerRegistration.register();

const styles = StyleSheet.create({
  backButton: {
    tintColor: 'black',
  },
  otherButtonIcon: {
    color: 'white',
    marginRight: 20,
  },
  dropdownMenu: {
    width: '30%',
    backgroundColor: '#ccc',
    alignSelf: 'flex-end',
    shadowRadius: 16,
    shadowColor: 'rgba(0,0,0,0)',
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.25,
    elevation: 2,
    //marginTop: -20,
    height: 'auto',
  },
  dropdownTextMenu: {
    fontSize: 16,
    textAlign: 'left',
  },
  //});
  //const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 305,
    height: 159,
    marginBottom: 20,
  },
  instructions: {
    color: '#888',
    fontSize: 18,
    marginHorizontal: 15,
    marginBottom: 10,
  },
  button: {
    backgroundColor: 'blue',
    padding: 20,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 20,
    color: '#fff',
  },

  screen: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    width: 400,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  imageContainer: {
    padding: 30,
  },
  image: {
    width: 400,
    height: 300,
    resizeMode: 'cover',
  },
});

import React from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { ListItem, Avatar, Badge } from 'react-native-elements';
import axios from 'axios';
import moment from 'moment';
import Constants from 'expo-constants';
import { AuthContext } from '../utils/ContextUtils';

export default class ChatList extends React.Component {
  constructor(props) {
    console.log('==================== ChatList.constructor start.');
    super(props);
    this.state = {
      data: [],
      instructions: [],
      instructionStatuses: [],
      isFetching: false,
    };
    this.willFocusSubscription = null;
    console.log('==================== ChatList.constructor end.');
  }

  componentDidMount() {
    console.log('==================== ChatList.componentDidMount start.');
    console.log(this.context.user.id);
    this.getInstructions();
    this.getInstructionStatuses();
    this.getChats();
    this.willFocusSubscription = this.props.navigation.addListener(
      'willFocus',
      () => {
        this.getChats();
      }
    );
    console.log('==================== ChatList.componentDidMount end.');
  }

  componentWillUnmount() {
    console.log('==================== ChatList.componentWillUnmount start.');
    this.willFocusSubscription.remove();
    console.log('==================== ChatList.componentWillUnmount end.');
  }

  getInstructions() {
    console.log('==================== ChatList.getInstructions start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/instructions', {
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        //console.log(response);
        let data = response.data;
        this.setState({ instructions: data });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== ChatList.getInstructions end.');
  }

  getInstructionStatuses() {
    console.log('==================== ChatList.getInstructionStatuses start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/instruction_statuses', {
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        //console.log(response);
        let data = response.data;
        this.setState({ instructionStatuses: data });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== ChatList.getInstructionStatuses end.');
  }

  getChats() {
    console.log('==================== ChatList.getChats start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/chats', {
        params: { user_id: this.context.user.id },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        //console.log(response.data);
        this.setState({ data: response.data });
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isFetching: false });
      });
    console.log('==================== ChatList.getChats end.');
  }

  createItemView(item) {
    let avatarUrl =
      Constants.manifest.extra.apiUrl +
      (item.field.avatar.url
        ? item.field.avatar.url
        : '/images/default_field.png');
    let title = item.field.farm.name + ' - ' + item.field.name;
    let subTitle = '';
    let createdAt = '';

    if (item.messages.length > 0) {
      let msg = item.messages[0];
      if (msg.picture.url) {
        if (!msg.user) {
          subTitle += Constants.manifest.name + 'が';
        } else if (msg.user.id !== this.context.user.id) {
          subTitle += msg.user.name + 'が';
        }
        subTitle += '写真を送信しました';
      } else {
        subTitle += msg.message;
      }

      if (msg.user) {
        let dt1 = moment().startOf('days'); // 現在日付の開始時間(YYYY-MM-DD 00:00:00)
        let dt2 = moment(msg.created_at);
        if (dt2.isSameOrAfter(dt1)) {
          // 最新のメッセージが本日日付に送信されている時は時刻を表示
          createdAt = dt2.format('H:mm');
        } else {
          // 最新のメッセージが本日日付より前に送信されている時は日付を表示
          createdAt = dt2.format('YYYY/MM/DD');
        }
      }
    }
    return (
      <ListItem
        onPress={() =>
          this.props.navigation.navigate('Chat', {
            chat_id: item.id,
            title: title,
            instructions: this.state.instructions,
            instructionStatuses: this.state.instructionStatuses,
          })
        }
        bottomDivider
      >
        <View>
          <Avatar source={{ uri: avatarUrl }} />
          {!item.read_flag && (
            <Badge
              status="error"
              badgeStyle={{ width: 15, height: 15, borderRadius: 10 }}
              containerStyle={{ position: 'absolute', top: -4, right: -4 }}
            />
          )}
        </View>
        <ListItem.Content>
          <ListItem.Title style={styles.title}>{title}</ListItem.Title>
          <ListItem.Subtitle>{subTitle}</ListItem.Subtitle>
        </ListItem.Content>
        <View>
          <Text style={styles.datetime}>{createdAt}</Text>
        </View>
      </ListItem>
    );
  }

  keyExtractor = (item, index) => item.id.toString();

  renderItem = ({ item }) => <View>{this.createItemView(item)}</View>;

  onRefresh() {
    console.log('==================== ChatList.onRefresh start.');
    this.setState({ isFetching: true });
    this.getChats();
    console.log('==================== ChatList.onRefresh end.');
  }

  render() {
    return (
      <View style={styles.container}>
        <FlatList
          keyExtractor={this.keyExtractor}
          data={this.state.data}
          renderItem={this.renderItem}
          onRefresh={() => this.onRefresh()}
          refreshing={this.state.isFetching}
        />
      </View>
    );
  }
}
ChatList.contextType = AuthContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  title: {
    marginTop: -10,
  },
  datetime: {
    fontSize: 12,
    color: 'gray',
    marginTop: -20,
  },
});

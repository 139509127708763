import React from 'react';
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import PropTypes from 'prop-types';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { connectActionSheet } from '@expo/react-native-action-sheet';

class AvatarUpdateUtils extends React.Component {
  // コンストラクタ
  constructor(props) {
    super(props);
    this.state = { avatarUrl: this.props.avatarUrl };
  }

  // カメラ起動イベントハンドラ
  openCamera = async (onLongPress) => {
    // カメラへのアクセス許可ダイアログ表示
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
    // アクセス許可確認
    if (permissionResult.granted === false) {
      alert('カメラへのアクセスを許可してください');
      return;
    }

    // カメラ表示
    const result = await ImagePicker.launchCameraAsync({
      allowsEditing: false,
      aspect: [4, 3],
    });
    if (!result.cancelled) {
      this.setState({ avatarUrl: result.uri });
      onLongPress({ avatar: result.uri });
      console.log(result.uri);
    }
  };

  // アルバム表示イベントハンドラ
  openAlbum = async (onLongPress) => {
    // アルバムへのアクセス許可ダイアログ表示
    const permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();
    // アクセス許可確認
    if (permissionResult.granted === false) {
      alert('アルバムへのアクセスを許可してください');
      return;
    }

    // アルバム表示
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
    });
    if (!result.cancelled) {
      this.setState({ avatarUrl: result.uri });
      onLongPress({ avatar: result.uri });
      console.log(result.uri);
    }
  };

  onActionsPress = () => {
    const options = ['カメラ撮影', 'アルバムから選択', 'キャンセル'];
    const cancelButtonIndex = options.length - 1;
    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async (buttonIndex) => {
        const { onLongPress } = this.props;
        switch (buttonIndex) {
          case 0:
            this.openCamera(onLongPress);
            return;
          case 1:
            this.openAlbum(onLongPress);
            return;
          default:
            return;
        }
      }
    );
  };

  render() {
    return (
      <TouchableOpacity style={{}} onLongPress={this.onActionsPress}>
        <Image
          style={styles.imageLogo}
          source={{ uri: this.state.avatarUrl }}
        />
      </TouchableOpacity>
    );
  }
}

const AvatarUtils = connectActionSheet(AvatarUpdateUtils);
export default AvatarUtils;
// コンテキスト
//AvatarUtils.contextTypes = { actionSheet: PropTypes.func }

const styles = StyleSheet.create({
  container: {
    //width: 26,//26,
    //height: 26,
    //marginLeft: 40,
    //marginBottom: 10,
    flex: 1,
  },
  buttonIcon: {
    color: '#aaa',
    marginTop: 0,
    textAlign: 'center',
  },
  imageLogo: {
    backgroundColor: '#fff',
    width: 100,
    height: 100,
    paddingTop: 100,
    borderRadius: 50,
  },
});

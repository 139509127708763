import React from 'react';
import { View, Text, Button, StyleSheet, FlatList } from 'react-native';
import { ListItem, Avatar } from 'react-native-elements';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import moment from 'moment';
import Constants from 'expo-constants';
import { AuthContext } from '../utils/ContextUtils';

const dataLoggerUrl = Constants.manifest.extra.dataLoggerUrl;

export default class HomeList extends React.Component {
  constructor(props) {
    console.log('==================== HomeList.constructor start.');
    super(props);
    this.state = { data: [], isFetching: false, fields: [] };
    this.willFocusSubscription = null;
    console.log('==================== HomeList.constructor end.');
  }

  componentDidMount() {
    console.log('==================== HomeList.componentDidMount start.');
    this.getSensorList();
    this.getFieldsList();
    this.getDataloggers();
    this.willFocusSubscription = this.props.navigation.addListener('willFocus', () => {
      this.getSensorList();
      this.getFieldsList();
      this.getDataloggers();
    });
    console.log('==================== HomeList.componentDidMount end.');
  }

  componentWillUnmount() {
    console.log('==================== HomeList.componentWillUnmount start.');
    this.willFocusSubscription.remove();
    console.log('==================== HomeList.componentWillUnmount end.');
  }

  getDataloggers() {
    console.log('==================== HomeList.getDataloggers start.');
    // console.log(dataLoggerUrl);
    axios
      .get(dataLoggerUrl + '/api/dataloggers', {})
      .then((response) => {
        this.setState({ data: response.data });
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isFetching: false });
      });
    console.log('==================== HomeList.getDataloggers end.');
  }

  // センサーのリスト
  getSensorList() {
    console.log('==================== HomeList.getSensorList start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/sensors', {
        params: { user_id: this.context.user.id },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        this.setState({ sensors: response.data });
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== HomeList.getSensorList end.');
  }

  // 区画のリスト
  getFieldsList() {
    console.log('==================== HomeList.getFieldList start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/fields', {
        params: { user_id: this.context.user.id },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        this.setState({ fields: response.data });
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== HomeList.getFieldList end.');
  }

  createItemView(field) {
    // センサーと区画ID
    let sensor_list = this.state.sensors;
    // datalogggerのsensorcodeと一致するやつ
    let logger = this.state.data;
    if (!logger.length) return;

    // sensor_code取得
    let sensor_code = '';
    for_sensor: for (const index in sensor_list) {
      const sensors_has_fields = sensor_list[index].sensors_has_fields;
      for (let i = 0; i < sensors_has_fields.length; i++) {
        if (field.id == sensors_has_fields[i].field_id) {
          sensor_code = sensor_list[index].code;
          break for_sensor;
        }
      }
    }
    // console.log(sensor_code);
    // foeldsがなければ表示しない
    if (!sensor_code) return;

    // console.log('field=' + field.id + '----sensor' + sensor_code);
    let tmpCondition = '';
    let humCondition = '';
    let sensorDateTime = '';
    let imageDateTime = '';
    let avatarUrl = dataLoggerUrl + '/images/default_no_image.png';
    let subTitlePlace = field.farm.name + ' - ' + field.name;

    // codeとloggerから
    for (const index in logger) {
      if (logger[index].sensor_code != sensor_code) continue;
      if (logger[index].sensor_type == 'TMP_C') {
        tmpCondition = '温度：' + (Math.round(logger[index].value * 10) / 10).toFixed(1) + ' ℃';
      }
      if (logger[index].sensor_type == 'HUM_%') {
        sensorDateTime = this.dateFormat(logger[index].created_at);
        humCondition = '湿度：' + (Math.round(logger[index].value * 10) / 10).toFixed(1) + ' ％';
      }
      if (logger[index].sensor_type == 'CAM_JPEG') {
        avatarUrl = dataLoggerUrl + logger[index].picture.thumb.url;
        imageDateTime = this.dateFormat(logger[index].created_at);
      }
    }
    // console.log(subTitleCondition);
    // console.log(avatarUrl);
    // console.log(logger);
    return (
      <ListItem bottomDivider>
        <View>
          <Avatar
            size="xlarge"
            source={{ uri: avatarUrl }}
            imageProps={{ resizeMode: 'contain' }}
          />
          <View>
            <ListItem.Subtitle style={styles.datetimeImage}>{imageDateTime}</ListItem.Subtitle>
          </View>
        </View>
        <View>
          <ListItem.Content style={styles.message}>
            <ListItem.Subtitle style={styles.place}>{subTitlePlace}</ListItem.Subtitle>
            <ListItem.Subtitle style={styles.condition}>{tmpCondition}</ListItem.Subtitle>
            <ListItem.Subtitle style={styles.condition}>{humCondition}</ListItem.Subtitle>
            <ListItem.Subtitle style={styles.dateTimeSonsor}>{sensorDateTime}</ListItem.Subtitle>
          </ListItem.Content>
        </View>
      </ListItem>
    );
  }

  dateFormat(dateString) {
    // Date オブジェクトを作成
    const date = new Date(dateString);
    if (!date) return;
    // 日付を指定されたフォーマットに変換
    const formattedDate =
      `${date.getFullYear()}/` +
      `${(date.getMonth() + 1).toString().padStart(2, '0')}/` +
      `${date.getDate().toString().padStart(2, '0')} ` +
      `${date.getHours().toString().padStart(2, '0')}:` +
      `${date.getMinutes().toString().padStart(2, '0')}`;

    console.log(formattedDate); // 出力例: "2023/10/09 14:21"
    return formattedDate;
  }

  keyExtractor = (item, index) => item.id.toString();
  renderItem = ({ item }) => <View>{this.createItemView(item)}</View>;

  onRefresh() {
    console.log('==================== HomeList.onRefresh start.');
    this.setState({ isFetching: true });
    this.getDataloggers();
    console.log(this.state);
    console.log('==================== HomeList.onRefresh end.');
  }

  render() {
    return (
      <View style={styles.container}>
        <FlatList
          keyExtractor={this.keyExtractor}
          data={this.state.fields}
          renderItem={this.renderItem}
          onRefresh={() => this.onRefresh()}
          refreshing={this.state.isFetching}
        />
      </View>
    );
  }
}

HomeList.contextType = AuthContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
  },
  message: {
    flex: 2,
    justifyContent: 'flex-start',
  },
  place: {
    marginBottom: 20,
    fontSize: 12,
    textAlign: 'right',
  },
  condition: {
    // marginTop: 20,
    fontSize: 20,
  },
  datetime: {
    fontSize: 18,
  },
  datetimeImage: {
    fontSize: 10,
    textAlign: 'right',
  },
  dateTimeSonsor: {
    position: 'relative',
    top: '24px',
    fontSize: 10,
    left: '37%',
  },
});

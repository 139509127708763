import React from 'react';
import {
  View,
  Text,
  Button,
  TextInput,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  StyleSheet,
  Keyboard,
  SectionList,
} from 'react-native';
import axios from 'axios';
import * as SecureStore from 'expo-secure-store';
// import { ConfirmDialog } from 'react-native-simple-dialogs';

import { AuthContext } from '../utils/ContextUtils';
import AvatarUtils from '../utils/AvatarUtils';
import Constants from 'expo-constants';

export default class Profile extends React.Component {
  constructor(props) {
    console.log('==================== Profile.constructor start.');

    super(props);
    this.state = {
      password: null,
      confirmPassword: null,
      //avatarUrl: null,
      //dialogVisible: false,
      fields: [],
      errMsg: null,
      loading: false,
    };
    this.textInputPassword = React.createRef();
    this.textInputConfirmPassword = React.createRef();
    console.log('==================== Profile.constructor end.');
  }

  componentDidMount() {
    console.log('==================== Profile.componentDidMount start.');
    this.getFields();
    console.log('==================== Profile.componentDidMount end.');
  }

  getFields() {
    console.log('==================== Profile.getFields start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/fields', {
        params: { user_id: this.context.user.id },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        // console.log(response.data);
        let data = response.data;

        // SectionListが使用できる形式に変換する
        let fields = [];
        let lastFarmId = null;
        //let lastFarmCode = '';
        let lastFarmName = '';
        let tmpFields = [];
        for (let i = 0; i < data.length; i++) {
          let field = data[i];

          if (!lastFarmId) {
            lastFarmId = field.farm.id;
            //lastFarmCode = field.farm.code;
            lastFarmName = field.farm.name;
          } else if (field.farm.id !== lastFarmId) {
            fields.push({
              title: /*lastFarmCode + ' ： ' + */ lastFarmName,
              data: tmpFields,
            });
            tmpFields = [];
            lastFarmId = field.farm.id;
            //lastFarmCode = field.farm.code;
            lastFarmName = field.farm.name;
          }
          tmpFields.push(/*field.code + ' ： ' + */ field.name);
        }
        if (tmpFields.length > 0) {
          fields.push({
            title: /*lastFarmCode + ' ： ' + */ lastFarmName,
            data: tmpFields,
          });
        }
        this.setState({ fields: fields });
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== Profile.getFields end.');
  }

  patchUser(user) {
    console.log('==================== Profile.patchUser start.');

    var formData = new FormData();
    if (user.password) {
      formData.append('registration[password]', user.password);
      formData.append(
        'registration[password_confirmation]',
        user.password_confirmation
      );
    }
    if (user.avatar) {
      let fileName = user.avatar.split('/').pop().split('\\').pop();
      let fileExtension = fileName.split('.').pop();
      let fileType = 'image/jpeg';
      if (fileExtension.toLowerCase() == 'png') {
        fileType = 'image/png';
      }
      formData.append('registration[avatar]', {
        uri: user.avatar,
        type: fileType,
        name: fileName,
      });
    }
    console.log(formData);

    // axios.postを使用してファイルデータを含むFormDataを送るとAndroidだけエラーになる
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      var READYSTATE_COMPLETED = 4; // readyStateの値
      if (xhr.readyState == READYSTATE_COMPLETED) {
        //console.log(xhr);
        var HTTP_STATUS_OK = 200; // HTTPステータス コード
        if (xhr.status == HTTP_STATUS_OK) {
          // サーバから受信したレスポンスを表示する
          this.setState({ loading: false });
          let userInfo = xhr.response;
          SecureStore.setItemAsync('user-info', JSON.stringify(userInfo));
          this.context.user = userInfo;

          // ダイアログを閉じる
          //this.onClose();
          if (user.password) {
            this.textInputPassword.current.clear();
            this.textInputConfirmPassword.current.clear();
            this.setState({ password: null, confirmPassword: null });
          }
        } else {
          //console.error( xhr.status );
          console.log('ERROR!!');
          console.log(xhr.response);
          this.setState({ loading: false, errMsg: xhr.response });
        }
      }
    };
    xhr.responseType = 'json';
    xhr.open(
      'PATCH',
      Constants.manifest.extra.apiUrl + '/api/auth/' + this.context.user.id
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.context.user.token);
    xhr.send(formData);

    console.log('==================== Profile.patchUser end.');
  }

  renderEditButton() {
    if (this.state.loading) {
      return <ActivityIndicator size="small" />;
    } else {
      return (
        <View style={styles.containerButton}>
          {/*<TouchableOpacity style={styles.containerCancel} onPress={() => this.onClose(false)}>
            <Text style={styles.textCancel}>閉じる</Text>
          </TouchableOpacity>*/}
          <TouchableOpacity
            style={styles.containerEdit}
            onPress={() => this.onSubmit()}
          >
            <Text style={styles.textEdit}>保存</Text>
          </TouchableOpacity>
          {/*<ConfirmDialog
            title="確認"
            message="変更を保存せずに閉じますか？"
            visible={this.state.dialogVisible}
            onTouchOutside={() => this.setState({ dialogVisible: false })}
            positiveButton={{
              title: 'はい',
              onPress: () => {
                this.setState({ dialogVisible: false });
                // メイン画面へ遷移
                this.props.navigation.navigate('Main');
              }
            }}
            negativeButton={{
              title: 'いいえ',
              onPress: () => this.setState({ dialogVisible: false })
            }}
          />*/}
        </View>
      );
    }
  }

  onImageLongPress(user) {
    console.log('==================== Profile.onImageLongPress start.');
    this.patchUser(user);
    //this.setState({ avatarUrl: user.avatar });
    console.log('==================== Profile.onImageLongPress end.');
  }

  /*
  onClose(autoClose = true) {
    console.log("==================== Profile.onClose start.");
    if (!autoClose && (this.state.password || this.state.password_confirmation || this.state.avatarUrl)) {
      // 変更が保存されていないため確認ダイアログ表示
      this.setState({ dialogVisible: true });
    } else {
      this.props.navigation.navigate('Main');
    }
    console.log("==================== Profile.onClose end.");
  }
*/

  onSubmit() {
    console.log('==================== Profile.onSubmit start.');
    this.setState({ loading: true, errMsg: null });
    Keyboard.dismiss();
    let user = {};
    if (this.state.password) {
      user.password = this.state.password;
      user.password_confirmation = this.state.confirmPassword;
    }
    //if (this.state.avatarUrl) {
    //  user.avatar = this.state.avatarUrl;
    //}
    if (Object.keys(user).length > 0) {
      this.patchUser(user);
    } else {
      // 変更点が無いため、そのままダイアログを閉じる
      //this.onClose();
      this.setState({ loading: false });
    }
    console.log('==================== Profile.onSubmit end.');
  }

  render() {
    const { ...props } = this.props;
    let avatarUrl =
      Constants.manifest.extra.apiUrl + '/images/default_user.png';
    if (this.context.user.avatar && this.context.user.avatar.url) {
      avatarUrl =
        Constants.manifest.extra.apiUrl + this.context.user.avatar.url;
    }
    let title = '';
    if (this.context.user.role >= 2) {
      title = this.context.user.role == 2 ? '契約中の' : '管理中の';
    }
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <AvatarUtils
            {...props}
            avatarUrl={avatarUrl}
            onLongPress={(user) => this.onImageLongPress(user)}
          />
          <Text style={styles.textTitle}>{this.context.user.name}</Text>
        </View>
        <View style={styles.footer}>
          {/*this.state.errMsg && this.state.errMsg.map((item, index) => <Text style={styles.textError} key={index}>{item}</Text>)*/}

          {/*<TextInput
            style={styles.textInput}
            placeholder="新しいパスワード（8文字以上）"
            onChangeText={(password) => this.setState({password})}
            secureTextEntry={true}
            ref={this.textInputPassword}
          />
          <TextInput
            style={styles.textInput}
            placeholder="パスワードの確認"
            onChangeText={(confirmPassword) => this.setState({confirmPassword})}
            secureTextEntry={true}
            ref={this.textInputConfirmPassword}
          />*/}

          {/*this.renderEditButton()*/}
          <Text style={styles.listTitle}>{title}フィールド一覧</Text>
          <SectionList
            sections={this.state.fields}
            renderItem={({ item }) => <Text style={styles.item}>{item}</Text>}
            renderSectionHeader={({ section }) => (
              <Text style={styles.sectionHeader}>{section.title}</Text>
            )}
            keyExtractor={(item, index) => index}
          />
        </View>
      </View>
    );
  }
}
Profile.contextType = AuthContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#009387',
  },
  header: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    //paddingHorizontal: 20,
    //paddingBottom: 100
    marginBottom: 30,
  },
  footer: {
    flex: 2,
    backgroundColor: '#fff',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
    //marginTop: 100
  },
  textTitle: {
    marginTop: 10,
    fontSize: 36,
    color: '#ffffff',
  },
  textInput: {
    height: 50,
    padding: 5,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'gray',
  },
  containerButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  containerEdit: {
    flex: 1,
    backgroundColor: '#009387',
    height: 50,
    justifyContent: 'center',
    borderRadius: 10,
    //marginLeft: 5
  },
  textEdit: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: 20,
  },
  containerCancel: {
    flex: 1,
    backgroundColor: '#ffffff',
    height: 50,
    justifyContent: 'center',
    borderRadius: 10,
    borderStyle: 'solid',
    borderColor: '#009387',
    borderWidth: 1,
    marginRight: 5,
  },
  textCancel: {
    textAlign: 'center',
    color: '#009387',
    fontSize: 20,
  },
  textError: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'center',
    color: 'red',
  },
  listTitle: {
    fontSize: 20,
    marginBottom: 20,
  },
  sectionHeader: {
    paddingTop: 3,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 3,
    fontSize: 18,
    fontWeight: 'bold',
    backgroundColor: 'rgba(247,247,247,1.0)',
  },
  item: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 10,
    fontSize: 16,
    height: 44,
    borderBottomWidth: 2,
    borderBottomColor: 'rgba(247,247,247,1.0)',
    //backgroundColor: 'red'
  },
});

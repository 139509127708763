import React, { createContext, useState, useContext, useEffect } from 'react';

//export const AuthContext = createContext({
//  user_id: 0,
//  user_name: ''
//});
export const AuthContext = createContext();
//export function useAuthContext() {
//  return useContext(AuthContext);
//}
/*
export function AuthProvider({ children }) {
  const [user, setUser] = useState('');

  const value = {
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}*/

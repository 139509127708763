import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Modal,
  Button,
  Text,
  Keyboard,
  Linking,
  Platform 
} from 'react-native';
// import WebBrowser from 'expo-web-browser';
import PropTypes from 'prop-types';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import axios from 'axios';
import Constants from 'expo-constants';
//import StatusList from './StatusList';
import { Slider } from 'react-native-elements';


export default class StatusUtils extends React.Component {
  // コンストラクタ
  constructor(props) {
    console.log('==================== STATUS UTIL start.');
    super(props);
    this.state = { modalVisible: false };
  }

  closeModal() {
    this.setState({ modalVisible: false });
  }

  onModalPress() {
    Keyboard.dismiss();
    this.setState({ modalVisible: true });
  }

  onPressIkusei(props) {
    let chatId = Number(props.chatId)
    let userId = Number(props.userId)
    console.log(chatId)
    console.log(userId)
    let url = Constants.manifest.extra.apiUrl 
              + '/breedings_crop_update?'
              + 'chat_id=' + chatId + '&'
              + 'user_id=' + userId
    
    // 外部リンクアクセス
    Linking.openURL(url).catch(err => console.error('URLを開けませんでした。', err));

    // API呼び出し
    // axios.get(Constants.manifest.extra.apiUrl + '/breedings_crop_update', {
    //   params: {
    //     chat_id: props.chatId,
    //     user_id: props.userId,

    //   },
    //   // headers: { Authorization: 'Bearer ' + this.context.user.token }
    // })

    // モーダルを閉じる
    this.setState({ modalVisible: false });
  }

  render() {
    const { ...props } = this.props;
    console.log(props)
    return (
      <TouchableOpacity style={styles.container} onPress={() => this.onModalPress()} >
          <FontAwesome5 style={styles.buttonIcon_ikusei} name="seedling" size={22} />
          <Modal 
              visible={this.state.modalVisible} 
              animationType={'fade'} 
              transparent={true}
              onRequestClose={() => this.closeModal()}
          >
              <View style={styles.modalContainer}>       
                  <View style={styles.container}>
                      <View style={styles.closeContainer}>
                          <TouchableOpacity 
                              style={styles.closeButtonContainer} 
                              onPress={() => this.closeModal()} // モーダルを閉じる
                          >
                            <Text style={styles.closeButtonText}>
                              ×
                            </Text>
                          </TouchableOpacity>
                      </View>
                      <View style={styles.modalMain}>
                        <Text style={styles.modaText}>
                          管理画面(育成状態の更新)へ移動しますか？
                        </Text>
                        <View style={styles.buttonGroup}>
                          <TouchableOpacity 
                            onPress={() => this.onPressIkusei(props)}
                            style={styles.button}>
                              <Text style={styles.buttonText}>管理画面へ</Text>
                          </TouchableOpacity>
                          
                          <TouchableOpacity 
                            onPress={() => this.closeModal()}
                            style={styles.cancelButton}>
                              <Text style={styles.buttonText}>キャンセル</Text>
                          </TouchableOpacity>
                        </View>
                      </View> 
                  </View>
              </View>
          </Modal>
      </TouchableOpacity>
    );
  }
}

// スタイルシート
const styles = StyleSheet.create({
    centerContainer: {
      textAlign: 'center',
    },
    container: {
      flex: 1,
    },
    buttonGroup: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonIcon_ikusei: {
      //color: '#aaa',
      color: '#63E6BE',
      marginTop: 0,
      textAlign: 'center',
    },
    modalMain: {
      // width: 100,
      textAlign: 'center',
      // color: '#ffffff',
      fontSize: 30,
    },
    buttonIcon: {
      //color: '#aaa',
      color: 'white',
      marginTop: 0,
      textAlign: 'center',
    },
    modalContainer: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      // margin: 30,
      
      marginTop: 300,
      marginBottom: 300,
      marginLeft: 30,
      marginRight: 30,

      borderRadius: 15,
      borderColor: '#000'
    },
    buttonClose: {
      padding: 20,
    },
    closeButtonContainer: {
      backgroundColor: '#009387',
      height: 40,
      width: 40,
      justifyContent: 'center',
      //padding: 10,
      borderRadius: 10,
    },
    closeButtonText: {
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 30,
    },
    closeContainer: {
      alignItems: 'flex-end',
      paddingHorizontal: 10,
      marginTop: 5,
      marginBottom: 5,
    },
    button: {
      // width: 150,
      // height: 40,
      padding: 10,
      borderRadius: 10,
      backgroundColor: '#009387',
      textAlign: 'center',
      // marginTop: 10,
      // marginBottom: 10,
      // marginRight: 30,
      // marginLeft: 30
      margin: 10,
    },
    buttonText: {
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 18,
    },
    cancelButton: {
      // width: 150,
      // height: 40,
      padding: 10,
      borderRadius: 10,
      backgroundColor: '#C0C0C0',
      textAlign: 'center',
      // marginTop: 10,
      // marginBottom: 10,
      // marginRight: 30,
      // marginLeft: 30
      margin: 10
    },
    modaText: {
      margin: 40
    }
  });
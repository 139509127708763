import * as SecureStore from 'expo-secure-store'; // NOTE: webでは使えない
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

const isWeb = () => {
  return Platform.OS === 'web' ? true : false;
};

export const deleteItemAsyncWeb = async (key) => {
  if (isWeb) {
    await AsyncStorage.removeItem(key);
  } else {
    await SecureStore.deleteItemAsync(key);
  }
};

export const getItemAsyncWeb = async (key) => {
  if (isWeb) {
    return await AsyncStorage.getItem(key);
  } else {
    return await SecureStore.getItemAsync(key);
  }
};

export const setItemAsyncWeb = async (key, user) => {
  if (isWeb) {
    await AsyncStorage.setItem(key, JSON.stringify(user));
  } else {
    await SecureStore.setItemAsync(key, JSON.stringify(user));
  }
};

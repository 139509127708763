import React from 'react';
import {
  View,
  Text,
  Button,
  StyleSheet,
  FlatList,
  Badge,
  TouchableOpacity,
  ImageBackground,
} from 'react-native';
import { ListItem, Avatar } from 'react-native-elements';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import moment from 'moment';
import Constants from 'expo-constants';
import { AuthContext } from './ContextUtils';

export default class InstructionList extends React.Component {
  constructor(props) {
    console.log('==================== InstructionList.constructor start.');
    super(props);
    console.log('==================== InstructionList.constructor end.');
  }

  createItemView(item) {
    //console.log(item);
    let pictureUrl = null;
    if (item.picture && item.picture.url) {
      pictureUrl = Constants.manifest.extra.apiUrl + item.picture.url;
    }
    return (
      <View style={styles.itemContainer}>
        <TouchableOpacity
          style={styles.itemButton}
          onPress={() => this.onPress(item)}
        >
          <ImageBackground
            style={styles.itemButtonImage}
            source={{ uri: pictureUrl }}
            resizeMode="contain"
          >
            {item.overlay_caption ? (
              <Text style={styles.itemButtonText}>{item.overlay_caption}</Text>
            ) : null}
          </ImageBackground>
        </TouchableOpacity>
      </View>
    );
  }

  keyExtractor = (item, index) => item.id.toString();

  renderItem = ({ item }) => (
    <View style={{ height: 120 }}>{this.createItemView(item)}</View>
  );

  onPress(item) {
    console.log('==================== InstructionList.onPress start.');
    //console.log(item);
    const { onSend } = this.props;
    let message = {};
    if (this.props.manager) {
      // 管理者
      message = {
        text: '【作業指示】\n ' + item.message,
        instruction: {
          id: item.id,
        },
      };
    } else {
      // 会員
      message = {
        text: '【作業報告】\n ' + item.message,
        instruction_status: {
          id: item.id,
        },
      };
    }
    onSend([message]); // 送信
    this.onPressClose();
    console.log('==================== InstructionList.onPress end.');
  }

  onPressClose() {
    const { onClose } = this.props;
    onClose(); // モーダルを閉じる
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.closeContainer}>
          <TouchableOpacity
            style={styles.closeButtonContainer}
            onPress={() => this.onPressClose()}
          >
            <Text style={styles.closeButtonText}>×</Text>
          </TouchableOpacity>
        </View>
        <FlatList
          keyExtractor={this.keyExtractor}
          data={this.props.instructions}
          renderItem={this.renderItem}
        />
      </View>
    );
  }
}
InstructionList.contextType = AuthContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  itemContainer: {
    padding: 10,
    backgroundColor: 'white',
  },
  itemButton: {
    height: 100,
    backgroundColor: 'white',
    borderWidth: 3,
    borderColor: '#009387',
    borderStyle: 'solid',
    borderRadius: 5,
    justifyContent: 'center',
  },
  itemButtonImage: {
    flex: 1,
    justifyContent: 'center',
  },
  itemButtonText: {
    textAlign: 'center',
    color: '#009387',
    fontWeight: 'bold',
    fontSize: 30,
  },
  closeContainer: {
    alignItems: 'flex-end',
    paddingHorizontal: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  closeButtonContainer: {
    backgroundColor: '#009387',
    height: 40,
    width: 40,
    justifyContent: 'center',
    //padding: 10,
    borderRadius: 10,
  },
  closeButtonText: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: 30,
  },
});

import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Modal,
  Button,
  Text,
  Keyboard,
} from 'react-native';
import PropTypes from 'prop-types';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import axios from 'axios';
import Constants from 'expo-constants';

import InstructionList from './InstructionList';

export default class InstructionUtils extends React.Component {
  // コンストラクタ
  constructor(props) {
    super(props);
    this.state = { modalVisible: false };
  }

  closeModal() {
    this.setState({ modalVisible: false });
  }

  /*
  onActionsPress = () => {
    let optionIds = [];
    let options = [];
    for (let i=0; i<this.props.instructions.length; i++) {
      let instruction = this.props.instructions[i];
      optionIds.push(instruction.id);
      options.push(instruction.instruction);
    }
    options.push('キャンセル');

    const cancelButtonIndex = options.length - 1;
    this.context.actionSheet().showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async buttonIndex => {
        const { onSend } = this.props;
        switch (buttonIndex) {
          case cancelButtonIndex:
            return;
          default:
            let message = {
              text: '【作業指示】\n ' + options[buttonIndex] + ' をお願いします。',
              instruction: {
                id: optionIds[buttonIndex]
              }
            };
            onSend([message]);
            return;
        }
      }
    )
  }
*/

  onModalPress() {
    Keyboard.dismiss();
    this.setState({ modalVisible: true });
  }

  render() {
    const { ...props } = this.props;
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={() => this.onModalPress()}
      >
        <FontAwesome5 style={styles.buttonIcon} name="tasks" size={22} />
        <Modal
          visible={this.state.modalVisible}
          animationType={'slide'}
          transparent={true}
          onRequestClose={() => this.closeModal()} // 端末自体の戻るボタンを押した時
        >
          <View style={styles.modalContainer}>
            <InstructionList
              {...props}
              manager={this.props.manager}
              instructions={this.props.instructions}
              onClose={() => this.closeModal()}
            />
          </View>
        </Modal>
      </TouchableOpacity>
    );
  }
}
// コンテキスト
InstructionUtils.contextTypes = { actionSheet: PropTypes.func };

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonIcon: {
    //color: '#aaa',
    color: 'white',
    marginTop: 0,
    textAlign: 'center',
  },
  modalContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    margin: 20,
  },
  buttonClose: {
    padding: 20,
  },
});
